<template>
  <el-form label-width="150px" ref="formData" :model="formData" :rules="rules">
      <div>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="付款户名:">
                <el-input disabled v-model="formData.name"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="电子账户:">
                <el-input disabled v-model="formData.bankNum"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="开户行:">
                <el-input disabled v-model="formData.bankName"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="可用余额:">
                <el-input disabled v-model="formData.useamtValue"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class">（币种：CNY；单位：元）</div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="本次支付金额:" prop="amountValue">
                <el-input type="number" @wheel.native.prevent="stopScroll($event)" v-model="formData.amountValue"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class">（币种：CNY；单位：元）</div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="16" :md="12"
            ><div>
              <el-form-item label="手机号码:">
                <span>{{ formData.mobile }}</span>
                <!-- <el-input v-model="formData.name"></el-input> -->
              </el-form-item></div
          ></el-col>
          <el-col :span="5"><div class="main_class"></div></el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="7"
            ><div>
              <el-form-item label="验证码:" prop="verificationCode">
                <el-input v-model="formData.verificationCode"></el-input>
              </el-form-item></div
          ></el-col>
          <el-col :span="5"
            ><div>
              <div class="main_buttom_class">
                <div>
                  <el-button type="primary" @click="sendSms">{{
                    timeStr
                  }}</el-button>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5"></el-col>
        </el-row>
      </div>
    </el-form>
</template>

<script>
export default {
  name: 'onLine',
  props:{
    creditProofId:{
      type: String,
      require: true,
    },
    mobile: {
      type: String,
      require: true,
    },
    maxPayAmount:{
      type: Number,
      default: 0,
    },
    proofId:{
      type: String,
      require: true,
    },
    productId:{
      type: String,
      require: true,
    },
  },
  watch:{
    maxPayAmount(newValue,oldValue){
      this.loadCompanyWallet();
      this.formData.amountValue =  this.accDiv(this.maxPayAmount,100)
    }
  },  
  data(){
    return {
      channel: process.env.VUE_APP_PLATFORM_CHANNEL,
      timeStr: "获取短信验证码",
      formData: {
        mobile: this.mobile,
        verificationCode: '',
        useamt: 0,
        useamtValue: 0,
        amount: 0,
        amountValue: 0,
        creditProofId: '',
        payServicer: '',
        productId: '',
      },
      rules: {
        amountValue: [
          { required: true, message: '支付金额必须', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (Number(value) <= 0) {
              return callback(new Error('支付金额不能小于0'));
            }
            let num = this.accMul(Number(value), 100);
            if(num > this.formData.useamt){
              return callback(new Error('支付金额金额不能大于可用余额'));
            }
            if(num > this.maxPayAmount){
              return callback(new Error('支付金额金额不能大于可支付金额'));
            }
            return callback();
          }, trigger: 'blur'},
        ],
        verificationCode: [
          { required: true, message: '验证码必须', trigger: 'blur' }
        ],

      }
    }
  },
  created(){
      this.loadCompanyWallet();
      this.formData.amountValue =  this.accDiv(this.maxPayAmount,100)
  },
  methods: {
      stopScroll(evt) {
        evt = evt || window.event;
        if (evt.preventDefault) {
          // Firefox
          evt.preventDefault();
          evt.stopPropagation();
        } else {
          // IE
          evt.cancelBubble = true;
          evt.returnValue = false;
        }
        return false;
      },
      async loadCompanyWallet() {
      var param = {
        productId:this.productId,
        proofId: this.proofId,
      };
      await this.api.paymentPlanApi.getPayAccountInfo(param).then((res) => {
        let data = res.data;
        if (data.data) {
          this.formData.useamt = data.data.useamt;
          this.formData.useamtValue = this.accDiv(data.data.useamt , 100);
          this.formData.name = data.data.name;
          this.formData.bankNum = data.data.bankNum
          this.formData.bankName = data.data.bankName;
        } else {
         this.formData.useamt = 0;
        }
        this.$forceUpdate();
      });
    },
    submitForm(func) {
      return this.$refs['formData'].validate(func);
    },
    async loadChannel() {
      let response = await this.api.platformApi.findDictionariesEnableItemList({
        code: this.channelCode,
      });
      return response.data.data;
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      if (this.formData.mobile == null || this.formData.mobile == "") {
        this.$message({
          message: "没有手机号，无法获取验证码",
          type: "warning",
        });
        return;
      }
      var params = {
        mobile: this.formData.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code() {
      this.show = true;
      var i = 60;
      var timer = setInterval(() => {
        i--;
        this.timeStr = i + "秒";
        if (i <= 0) {
          clearInterval(timer);
          this.timeStr = "重新获取";
        }
      }, 1000);
    },
  }
}
</script>

<style scoped>

.main_buttom_class {
  padding-left: 15px;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}

</style>