<template>
  <div id="loanProcessingDetails" v-loading="loading">
    <el-row>
      <el-col>
        <span>应付帐信息</span>
      </el-col>
    </el-row>
    <div class="table padding_class">
      <table>
        <tr>
          <td>应付帐编号</td>
          <td>{{ paymentPlanInfoData.thirdParty }}</td>
        </tr>
        <tr>
          <td>付款方</td>
          <td>{{ paymentPlanInfoData.initiatorName }}</td>
        </tr>
        <tr>
          <td>收款方</td>
          <td>{{ paymentPlanInfoData.acceptorName }}</td>
        </tr>
        <tr>
          <td>付款金额</td>
          <td>{{ paymentPlanInfoData.amount / 100 }}</td>
        </tr>
        <tr>
          <td>已支付金额</td>
          <td>{{ paymentPlanInfoData.paidAmount / 100 }}</td>
        </tr>
        <tr>
          <td>最后支付时间</td>
          <td>{{ paymentPlanInfoData.lastPaidTime }}</td>
        </tr>
        <tr>
          <td>支付结果状态</td>
          <td>{{  dictionary(paymentPlanStatusArr,paymentPlanInfoData.status) }}</td>
        </tr>
      </table>

      <div class="marginCss">
        <el-row>
          <el-col>
            <span class="leftCss">所属对账单</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        id="personTable"
        :data="statementList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="thirdParty"
          label="对账单编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="对账用户名称"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="generateExpense"
          label="对账单总费用"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.generateExpense / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="bzCreateTime"
          label="对账时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="taxRate"
          label="操作"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="marginCss">
        <el-row>
          <el-col>
            <span>确权记录</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        id="personTable"
        :data="logList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        class="overflow"
      >
      <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        />
      <el-table-column
        prop="productName"
        label="确权产品"
        show-overflow-tooltip
        align="center"
      />        
        <el-table-column
          prop="amount"
          label="确权金额"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.amount!=null? accDiv(scope.row.amount , 100) : 0 }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="expireDate"
          label="确权日期"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="操作人"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="操作时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="操作人联系方式"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          :show-overflow-tooltip="true"
          align="center"
          class="overflow"
          width="150"
        >
        <template  slot-scope="scope">
          <!-- <span>{{ scope.name }}</span> -->
          <div >
            <el-link type="primary" class="payFreight" :underline="false">支付运费</el-link>
            <div class="payDiv" style="position: absolute;">
              <el-link type="primary" @click.prevent="currentPaymentItem = scope.row;onLineDialog = true;thisPayAmount(scope.row)" :underline="false">在线支付</el-link><br/>
              <el-link type="primary" @click.prevent="currentPaymentItem = scope.row;offlinePayDialog = true;thisPayAmount(scope.row)" :underline="false">线下支付</el-link>
            </div>
          </div>
        </template>
        </el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row>
          <el-col>
            <span>支付记录</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        id="personTable"
        :data="accountsPayablesList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
      <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="payerAcctNo"
          label="支付账号"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="payerName"
          label="付款方"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payeeName"
          label="收款方"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="支付途径"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payAmount"
          label="支付金额"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.payAmount / 100 }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="支付结果"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template  slot-scope="scope">
          <span>{{ dictionary(statusArr,scope.row.status) }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="支付时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="操作人"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        
        <el-table-column
          prop="mobile"
          label="操作人联系方式"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="state"
          label="操作"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
          <el-link type="primary"
          :style="!scope.row.transferId ? 'color: #cdcdcd' : 'color: #66b1ff'"
          :disabled="!scope.row.transferId"
           @click.prevent="downloadVoucher(scope.row)" :underline="false">下载凭证</el-link>
        </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      @open="openDialog"
      class="dialogBox"
      title="支付运费：在线支付"
      :close-on-click-modal="false"
      :visible.sync="onLineDialog"
      :destroy-on-close="true">
      <div>
        <onLine ref="onLineButton" :mobile="userInfo.mobile" :creditProofId="currentPaymentItem.creditProofId" :productId="currentPaymentItem.productId" :proofId="billPayable.id" :maxPayAmount="maxPayAmount" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onLineDialog = false">取 消</el-button>
        <el-button type="primary" :loading="dialogButton" @click="onLineButton" >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      @open="openDialog"
      class="dialogBox"
      title="支付运费：线下支付"
      :close-on-click-modal="false"
      :visible.sync="offlinePayDialog"
      :destroy-on-close="true">
      <div>
        <offlinePay ref="offlinePay" :mobile="userInfo.mobile" :maxPayAmount="maxPayAmount" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offlinePayDialog = false">取 消</el-button>
        <el-button type="primary" :loading="dialogButton" @click="offlinePayButton">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import dictionaryService from "@/service/common/dictionaryService.js";

import onLine from "./onLine";

import offlinePay from "./offlinePay";

export default {
  components: {onLine,offlinePay},
  data() {
    return {
      dialogButton: false,
      channel: process.env.VUE_APP_PLATFORM_CHANNEL,
      userInfo: {},
      onLineDialog: false,
      offlinePayDialog: false,
      loading: true, 
      settlementBillStatusCode: process.env.VUE_APP_ENUM_SETTLEMENT_BILL_STATUS,
      statusArr: [],
      settlementBillStateCode: process.env.VUE_APP_ENUM_SETTLEMENT_BILL_STATE,
      stateArr: [],
      creditProofAssetsState: process.env.VUE_APP_ENUM_CREDIT_PROOF_ASSETS_STATE,
      assetsStateArr: [],
      paymentPlanStatusCode: process.env.VUE_APP_ENUM_PAYMENT_PLAN_STATUS,
      paymentPlanStatusArr: [],
      paymentPlanInfoData: {},
      billPayable: {
        acceptorThirdParty: "",
        acceptorThirdPartyName: null,
        amount: 0,
        closeTime: null,
        initiatorThirdParty: "",
        initiatorThirdPartyName: null,
        paidAmount: 0,
        paymentTime: "",
        status: 0,
        statusLabel: null,
        thirdParty: "",
        id: "",
      },
      possessInvoice: [],
      billPayableList: [],
      list: [],
      accountsPayablesList: [],
      // 确权记录列表
      logList:[],
      // 结账单列表
      statementList: [],
      // 当前选中的支付项
      currentPaymentItem: {},
      // 可支付金额
      maxPayAmount: 0,
    };
   
  },
  async mounted() {
    this.billPayable = JSON.parse(localStorage.getItem("paymentPlanParticulars"));
    // this.loading = false;
    await this.loadData();
    await this.loadState();
    await this.loadUserInfo();
  },
  methods: {
    // 打开线上支付
    onLineButton(){
      this.$refs.onLineButton.submitForm((valid) => {
        let data = this.$refs.onLineButton.formData;
        console.log(valid);
        if(valid){
          data.productId = this.currentPaymentItem.productId;
          data.payServicer = this.channel;
          data.creditProofId = this.currentPaymentItem.creditProofId;
          data.amount = Number(data.amountValue) * 100;
          this.onLineSubmit(data);
        }
      })
    },
    // 提交线上支付
    async onLineSubmit(param){
      this.dialogButton = true;
      this.api.paymentPlanApi.onlinePayCreditProof(param).then(res => {
        let data = res.data;
        console.log(data);
        this.dialogButton = false;
        if(data.code != 200){
          this.$message.error(data.msg);
        }else{
          this.$message({
            message: '支付成功',
            type: 'success'
          });
          this.onLineDialog = false;
          // 加载确权记录
          this.loadConfirmationRecord();
          // 支付记录
          this.listAccountsPayables();
        }
      }).catch(err => {
        console.log(err);
      })
      
    },
    // 线下支付验证
    offlinePayButton(){
      this.$refs.offlinePay.submitForm((valid) => {
        let data = this.$refs.offlinePay.formData;
        console.log(valid);
        if(valid){
          data.payServicer = this.channel;
          data.creditProofId = this.currentPaymentItem.creditProofId;
          data.amount = Number(data.amountValue) * 100;
          this.offlinePaySubmit(data);
        }
      })
    },
    // 提交线下支付
    async offlinePaySubmit(param){
      this.dialogButton = true;
      this.api.paymentPlanApi.offlinePayCreditProof(param).then(res => {
        let data = res.data;
        console.log(data);
        this.dialogButton = false;
        if(data.code != 200){
          this.$message.error(data.msg);
        }else{
          this.$message({
            message: '支付成功',
            type: 'success'
          });
          this.offlinePayDialog = false;
          // 加载确权记录
          this.loadConfirmationRecord();
          // 支付记录
          this.listAccountsPayables();
        }
      }).catch(err => {
        console.log(err);
      })
    },
    dictionary(arr,num){
      if(arr == null || arr[num] == null){
        return '';
      }
      return arr[num].name;
    },
    // 加载用户信息
    async loadUserInfo(){
      await this.api.platformApi.getUserInfo({}).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.userInfo = data.data;
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
    openDialog(){
      this.dialogButton = false;
    },
    async loadData(){
      this.loading = true;
      // 加载应付帐信息
      await this.paymentPlanInfo();
      // // 加载持有发票信息
      // await this.loadPossessInvoice();
      // // await this.loadBillPayableList();
      // // 加载应付帐结算情况
      // await this.listAccountsPayables();
      // // await this.findCreditProofAssetsUseInfoByProofId();
      // // 加载额度使用信息
      // await this.lisPaymentUsage();
      // 2.1  
      // 加载所属对账单
      await this.lisStatement();
      // 加载确权记录
      await this.loadConfirmationRecord();
      // 支付记录
      await this.listAccountsPayables();
      this.loading = false;
    },
    // 所属对账单
    async lisStatement(){
      // 4fb96caeff85194bea29012dc9cd4e98
      await this.api.paymentPlanApi.statement({id: this.billPayable.statementBillId}).then(res => {
        let data = res.data;
        if(data.code != 200){
          // this.$message.error(data.msg);
        }else{
          this.statementList = [];
          this.statementList.push(data.data);
        }
      }).catch(err => {
        console.log(err);
      })
    },
    // 加载确权记录
    async loadConfirmationRecord(){
      // 639fd6a116a2e7e071d91badc40af657
      let param  = {
        proofId: this.billPayable.id,
      }
      await this.api.paymentPlanApi.getConfirmationRecord(param).then(res => {
        let data = res.data;
        console.log(data);
        if(data.code == 200){
          this.logList = data.data;
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
     async paymentPlanInfo() {
      await this.api.paymentPlanApi.getProofAndPaymentPlanByProofId({proofId: this.billPayable.id}).then(res => {
        let data = res.data;
        if(data.code != 200){
          this.$message.error(data.msg);
        }else{
          this.paymentPlanInfoData = data.data;
        }
      }).catch(err => {
        console.log(err);
      })
    },
    // async loadPossessInvoice(){
    //    await this.api.paymentPlanApi.possessInvoice(this.billPayable).then(res => {
    //      let data = res.data;
    //      if(data.code == 200){
    //        this.possessInvoice = data.data;
    //      }else{
    //        this.$message.error('持有发票信息,加载失败！');
    //      }
    //      console.log(res);
    //    });
    // },
    // async loadBillPayableList(){
    //   await this.api.paymentPlanApi.billPayableList(this.billPayable).then(res => {
    //     let data = res.data;
    //      if(data.code == 200){
    //         this.billPayableList = data.data;
    //      }else{
    //        this.$message.error('应付帐结算情况,记载失败！');
    //      }
    //      console.log(res);
    //   });
    // },
    async listAccountsPayables(){
      // '06a1c403a87cc1e8bc2671b72d66dbcf'
      await this.api.paymentPlanApi.listAccountsPayables({id: this.billPayable.id}).then(res => {
        let data = res.data;
        console.log(data);
         if(data.code == 200){
            this.accountsPayablesList = data.data;
         }else{
           this.$message.error('应付帐结算情况,加载失败！');
         }
         console.log(res);
      });
    },
    // 可支付金额
    async thisPayAmount(row){
      console.log(row);
      await this.api.paymentPlanApi.thisPayAmount({id: row.creditProofId}).then(res => {
        let data = res.data;
        console.log(data);
         if(data.code == 200){
            this.maxPayAmount = data.data;
         }else{
           this.$message.error('可支付金额,加载失败！');
         }
      });
    },
    // async findCreditProofAssetsUseInfoByProofId() {
    //   let response = await this.api.postLoanManagementApi.findCreditProofAssetsUseInfoByProofId({id:this.billPayable.id});
    //   this.list = response.data.data;
    // },
    async lisPaymentUsage() {
      let response = await this.api.paymentPlanApi.lisPaymentUsage({id:this.billPayable.id});
      console.log(response);
      this.list = response.data.data;
    },
    loadState() {
      dictionaryService
        .findDictionariesItemList(this.paymentPlanStatusCode)
        .then((res) => {
          this.paymentPlanStatusArr = res;
        }).catch((err) => {
      });
      // 加载支付状态
      dictionaryService
        .findDictionariesItemList(this.settlementBillStatusCode)
        .then((res) => {
          this.statusArr = res;
        })
        .catch((err) => {
        });
        // 加载业务状态
      // dictionaryService
      //   .findDictionariesItemList(this.settlementBillStateCode)
      //   .then((res) => {
      //     this.stateArr = res;
      //   })
      //   .catch((err) => {
      //   });
        // 
      // dictionaryService
      //   .findDictionariesItemList(this.creditProofAssetsState)
      //   .then((res) => {
      //     this.assetsStateArr = res;
      //   })
      //   .catch((err) => {
      //   });
    },
    async downloadVoucher(row){
      console.log(row);
       await this.api.paymentPlanApi.downloadVoucher({transferId: row.transferId}).then(res => {
        let data = res.data;
        console.log(data);
         if(data.code == 200){
            window.open(data.data.fileUrl);
         }else{
           this.$message.error(data.msg);
         }
         console.log(res);
      });
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0;overflow: visible;";
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  margin-right: 27px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
>>> .el-table__body-wrapper{
  overflow: visible;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
.margin_right {
  margin-right: 10px;
}
.dialogBox {
  text-align: left;
}

.overflow{
  overflow: visible;
}
.payDiv{
  position: absolute;
  margin-left: 35px;
  background-color: #FFF;
  z-index: 9;
  visibility: hidden;
  display: none;
}
.payFreight,.payFreight:hover{
  color: #BFBF00;
}
.payFreight:hover+.payDiv,.payDiv:hover{
  visibility: visible;
  display: block;
}
</style>